@font-face {
    font-family: "AkuKamu";
    src: url("./assets/fonts/Aku&Kamu.otf") format("opentype");
}


* {
    margin: 0;
    padding: 0;
}

html,
body {
    /* overflow: hidden; */
    height: 100%;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    outline: none;
}


#fullContent {
    z-index: 12;
    /* position: fixed; */
    top: 0;
    left: 0;
    opacity: 1;
    right: 0;
    min-height: 100%;
    overflow-y: auto;
    background: black;
    background: url(./assets/img/BG_1@2x.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    /* button {
        display: flex;
        
        &.off {
            display: none;
        }
    }

    button {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    h1 {
        margin-bottom: 30px;
    }

    p:first-of-type {
        margin-bottom: 10px;
    } */

}

#homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* height: auto; */
    overflow-y: auto;
    /* overflow-y: auto; */
    /* overflow-y: auto; */
    overflow-x: hidden;
    max-width: 100%;
}

#outer-mobile_control {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 0;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    display: none;
}

#outer-mobile_control.active {
    display: initial;
}

#inner-mobile_control {
    position: absolute;
    background: rgb(255, 255, 255, 0.8);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 11;
    top: 50%;
    left: 50%;
    pointer-events: none;
    transform: translate3d(-50%, -50%, 0);
}

/* #pauseScreen {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    opacity: .3;
    right: 0;
    bottom: 0;
    background: white;
} */

#startSound {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;
}

.point {
    position: absolute;
    top: 50%;
    left: 50%;
}

.point.visible {
    position: absolute;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: solid 1px #FFEB06;
    border-color: #FFEB06;
    z-index: 1000;
    animation-name: ActionBtn;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    transform-origin: center;
    transform: translate(-15px, -15px);
}

.point .label {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* background: #00000077;
    border: 1px solid #ffffff77; */
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    transform: scale(0, 0);
    cursor: pointer;
    transition: transform 0.3s;
}

.point .label img {
    width: 60px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.point .text {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 1.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    cursor: help;
}

.point:hover .text {
    opacity: 1;
}

.point.visible .label {
    transform: scale(1, 1);
}

#fullContent.off {
    display: none;
}

#screamer,
#introVideoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: black;
    opacity: 0;
    z-index: 2;
    bottom: 0;
    pointer-events: none;
}

#screamerImg,
#introVideo, #introVideo2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    display: none;
    z-index: 1000;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    display: initial;
    width: 100%;
    opacity: 1;
    height: auto;
    margin: auto;
}

#fullContent {
    /* display: none; */
}

#choisewallet {
    /* z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    right: 0;
    bottom: 0;
    background: white;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    height: auto;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#choisewallet button {
    display: block;
    cursor: pointer;
    width: 40%;
    margin: auto 5%;
    height: 150px;
    border-radius: 5px;
    border: none;
    background: white;
    transition: .2s all ease-in-out;
    font-size: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#choisewallet button img {
    height: 50px;
    width: auto;
    margin-top: -9px;
}

#choisewallet button#walletconnect:hover {
    background: rgb(240, 240, 240);
}

#choisewallet button#coinbaseconnect {
    background: #c03d00;
    color: white;
}

#choisewallet button#coinbaseconnect:hover {
    background: #B52906;
}

#endScreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #161616;
    background: url(./assets/img/orangeBg.jpg);
    background-size: cover;
    color: white;
    opacity: 0;
    pointer-events: none;
    transition: 1s all ease-in-out;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    height: 100%;
}

#endScreen .fullEnd {
    width: 80%;
    max-width: 600px;
    margin: auto;
    margin-bottom: 0;
}

#endScreen .left_part {
    width: 40%;
    margin-top: 60px;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

#endScreen .left_part .ctas {
    display: flex;
    align-self: center;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    margin-top: 60px;
}

#endScreen .rightPart {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60%;
}

#reecouter {
    margin-bottom: auto;
}

#endScreen .rightPart img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

#endScreen .rightPart img.cartes {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0%;
    z-index: 10;
    left: 0%;
}

#endScreen h2 {
    font-family: 'AkuKamu';
    color: #c03d00;
    margin-bottom: 30px;
    font-size: 32px;
}

#endScreen p {
    font-family: "Helvetica";
    font-size: 21px;
}

#endScreen footer {
    margin-top: auto;
    margin-bottom: 0;
}

#endScreen footer p {
    font-family: "AkuKamu";
    font-size: 12px;
}

#endScreen button {
    border: solid 2px white;
    background: none;
    color: white;
    padding: 10px 30px;
    font-family: "AkuKamu";
    font-size: 24px;
    border-radius: 50px;
    margin-right: 30px;
}

#endScreen .ctas a {
    color: #c03d00;
    font-family: 'AkuKamu';
    font-size: 24px;
    border-bottom: solid 2px #c03d00;
    text-decoration: none;
}

#endScreen a:hover {
    border-bottom: none;
    border-bottom: solid 2px transparent;
}

.desktop {
    display: initial;
}

.mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    #endScreen .left_part {
        width: 80%;
    }

    #endScreen {
        overflow-y: auto;
    }

    .main_part {
        display: flex;
        flex-direction: column;
    }

    #endScreen .rightPart {
        position: relative;
        width: 100%;
    }

    #endScreen .rightPart img {
        width: 100%;
        height: auto;
        position: initial;
        top: unset;
        bottom: unset;
        right: unset;
        left: unset;
    }

    .desktop {
        display: none !important;
    }

    .mobile {
        display: initial !important;
    }
}

@media screen and (max-width: 1240px) {
    #endScreen .left_part .ctas {
        flex-direction: column;
        align-items: flex-start;
    }

    #endScreen button {
        /* margin-bottom: 30px; */
    }
}

@media screen and (max-width: 728px) {
    #endScreen .left_part .ctas {
        align-items: center;
    }
}


#homepageStart {
    border: solid 2px white;
    color: white;
    padding: 10px 30px;
    border-radius: 60px;
    margin-top: 50px;
    color: white;
    font-size: 21px;
    cursor: not-allowed;
    background: linear-gradient(to left, lightgray 50%, #c03d00 50%) 100%;
    font-family: 'AkuKamu';
    transition: .5s all ease-in-out;
    background-size: 200% !important;
    margin-bottom: 100px;
    max-width: 80%;

}

#homepageStart.active {
    animation-name: bump;
    letter-spacing: 1px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}



@keyframes bump {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.1);
    }

    35% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

#homepageStart.active:hover {
    background: linear-gradient(to left, white 50%, white 50%) 0% center !important;
    /* background: linear-gradient(to left, white 50%, white 50%) 100%!important; */
    color: #B52906;
    border: solid 2px #B52906;
}

.small_zqd {
    vertical-align: middle !important;
    margin-top: -5px;
    width: 50px;
}

.smallArrows {
    vertical-align: middle !important;
    width: 50px;
}

.splide__arrows {
    width: 100vw;
    z-index: 10;
    pointer-events: none;
    margin: 0;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.splide__arrow {
    background: #c03d00 !important;
    width: 3em !important;
    border: solid 2px white !important;
    height: 3em !important;
}

@media screen and (min-width: 728px) {
    .splide__arrow {
        width: 4em !important;
        height: 4em !important;
    }
}


.splide__arrow svg {
    fill: white !important;
}

#homepageStart:hover {
    background-position: left;
}

.splide__track {
    height: auto;
    display: flex;
    align-items: center;
}

.splide__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.main_logo {
    margin-top: 100px;
    width: 676px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
}

.main_logo.small {
    width: 400px;
}

.splide__arrow--prev {
    left: 10% !important;
    pointer-events: initial;
}

.splide__arrow--next {
    right: 10% !important;
    pointer-events: initial;
}

@media screen and (max-width: 728px) {
    .main_logo {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
    }

    .splide__arrow--prev {
        left: 1em !important;
        pointer-events: initial;
    }

    .splide__arrow--next {
        right: 1em !important;
        pointer-events: initial;
    }
}

#pauseScreen {
    display: none;
}

.splide__slide>p {
    color: white;
    text-transform: uppercase;
    margin: 30px auto;
    font-weight: bold;
    font-family: "Helvetica";
    font-size: 28px;
    max-width: 90vw;
    text-align: center;
}

.splide__pagination {
    /* margin-bottom: 30px; */
    bottom: -1.5em !important;
}

button.splide__pagination__page {
    width: 20px !important;
    border-radius: 5px;
    border: solid 1px #B52906 !important;
    background: none !important;
    height: 10px !important;
    transition: .2s all ease-in-out !important;
}

button.splide__pagination__page.is-active {
    width: 50px !important;
    transform: scale(1) !important;
    opacity: 1 !important;
    background: #B52906 !important;
}

.tuto-card {
    width: 250px;
    height: 300px;
    background: white;
    border-radius: 25px;
    flex-direction: column;
}

.tuto-card .point.visible {
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.tuto-card .top-card {
    width: 96%;
    height: 60%;
    background: url(./assets/img/chrome-bg.jpg);
    background-size: cover;
    margin: 2% 5px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
}

.duoTopCard,
.duoTuto {
    display: flex;
    align-items: center;
}

.duoTuto p {
    color: white;
    font-family: "AkuKamu";
    font-size: 21px;
    margin: 0 30px;
}

.tuto-card .top-card.row {
    display: flex;
    align-items: center;
}

.tuto-card p {
    font-family: 'AkuKamu';
    font-weight: 400;
    font-size: 18px;

    /* line-height: 54px; */
    margin: 15px auto;
    text-align: center;
    color: #161616;

}

.tuto-card .border {
    border: 5px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

#doorPoints {
    pointer-events: auto;
}

.soundBar {
    border: solid 2px white;
    background: rgba(220, 220, 220);
    position: relative;
    width: 100px;
    height: 10px;
    margin-left: 5px;
}

.tuto-card .top-card>img {
    width: 100px;
}

.tuto-card .top-card>img.giflearn {
    width: 200px;
}

.soundBar::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    background: black;
    width: 60%;
    animation-name: soundCard;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.sound-row {
    display: flex;
    margin-top: 20px;
}

.mobile {
    display: flex;
}

.desktop {
    display: none;
}

@media (hover: none) and (pointer: coarse) {
    .mobile {
        display: flex;
    }

    .desktop {
        display: none;
    }
}

@media (hover: hover) and (pointer: fine) {
    .desktop {
        display: flex;
    }

    .mobile {
        display: none;
    }
}

/* .mobile {
    display: flex;
}

.desktop {
    display: none;
} */

@keyframes soundCard {
    0% {
        width: 10%;
    }

    50% {
        width: 80%;
    }

    70% {
        width: 70%;
    }

    95% {
        width: 70%;
    }

    100% {
        width: 10%;
    }
}

@keyframes ActionBtn {
    0% {
        border-width: 0px;
        width: 50px;
        height: 50px;
    }

    50% {
        border-width: 8px;
        width: 20px;
        height: 20px;
    }

    100% {
        border-width: 0px;
        width: 50px;
        height: 50px;
    }
}

.chromeAdvice {
    color: white;
    font-family: "AkuKamu";
    display: none;
    max-width: 80%;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    margin-top: -50px;
}

.chromeAdvice.active {
    display: initial;
}

.chromeAdvice a {
    color: white;
}

.silent {
    display: none;
}

.silent.active {
    display: initial;
}

#interactiveContent {
    pointer-events: initial;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* pointer-events: none; */
    display: none;
    z-index: 1000;
}

#interactiveContentBackdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

#closeInteractiveContent {
    position: absolute;
    top: 10%;
    right: 10%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fakeWindowsTopBar {
    height: 40px;
    top: 0;
    position: sticky;
    width: 100%;
    background: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.09);
}

.fakeWindowsClose {
    cursor: pointer;
    width: 50px;
    height: 100%;
    background: #B52906;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fakeWindowsClose img {
    filter: invert(1);
    width: 10px;
    height: auto;
}

#interactiveContentImageContainer {
    width: 80%;
    border-radius: 5px;
    max-height: 100%;
    max-height: 80%;
    display: flex;
    position: absolute;
    top: 10%;
    flex-direction: column;
    left: 10%;
    object-fit: contain;
    border: solid 2px white;
    overflow-y: hidden;
    align-items: center;
    height: auto;
    justify-content: center;
}

#interactiveContentImageContainerImage {
    max-height: calc(100% - 40px);
    width: 100%;
    height: auto;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topMailBox {
    background: white;
}

.fullScreenImg {
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    /* max-height: 100%; */
    /* overflow: scroll; */
}

.mainMailBox {
    background: #EBEBEB;
}

#leftPartMainMailBox {
    overflow: auto;
    max-height: 60vh;
    background: rgb(251 251 251);
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 15px;
    margin: 30px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.09);
}

@media screen and (max-width: 728px) {
    #leftPartMainMailBox {
        width: calc(100% - 20px);
        height: 200px;
        margin: 10px;
    }
}

.mailPreview {
    padding: 30px 0px;
    margin: 0px 30px;
    margin-bottom: 0;
    margin-top: 0;
    font-family: "Helvetica";
    border-bottom: solid 1px gray;
    transition: .1s all ease-in-out;
    cursor: pointer;
}

@media screen and (max-width: 728px) {
    .mailPreview {
        padding: 10px 0px;
        margin: 0px 10px;
    }
}

/* .mailPreview:hover {
    background: rgb(230, 230, 230);
} */

.mailPreview h3 {
    margin-bottom: 5px;
}

.mailPreview .auteur {
    margin-bottom: 30px;
    color: #B52906;
}

.mailPreview .auteur span {
    background: rgba(231, 52, 7, 0.23);
    font-size: 10px;
    margin-right: 10px;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 3px;
}

#rightPartMainMailBox {
    /* background: rgb(251 251 251); */
    width: 60%;
    padding: 30px;
    font-family: "Helvetica";
}

.mailFull h3 {
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: bold;
}

#rightPartMainMailBox p {
    margin-bottom: 10px;
}

#rightPartMainMailBox .mailFull .auteur {
    color: #B52906;
    margin-bottom: 35px;
    margin-top: 10px;
}

.mailFull .auteur span {
    background: rgba(231, 52, 7, 0.23);
    font-size: 10px;
    margin-right: 10px;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 3px;
}

.mainMailBox {
    display: flex;
    max-height: 100%;
    height: 100%;
}

@media screen and (max-width: 728px) {
    .mainMailBox {
        flex-direction: column;
    }
}

#mailBoxContent,
#hackScreen {
    max-height: calc(100% - 40px);
    height: 100%;
    width: 100%;
    overflow: auto;
}

#transitionScreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    pointer-events: none;
    z-index: 100;
    transition: .6s all ease-in-out;
}

#transitionScreen.in {
    display: initial;
}

#transitionScreen.black {
    background: black;
}

#hackScreen {
    background: black;
    color: #00ff00;
    box-sizing: border-box;
    padding: 30px;
    font-family: Consolas, monaco, monospace;
    padding-bottom: 60px;
}

#hackScreen span {
    margin-bottom: 30px;
    display: block;

}

#hackScreen p {
    margin: 10px;
}

@keyframes cursor-blink {
    0% {
        opacity: 0;
    }
}

#hackScreen #cursor::after {
    content: "";
    width: 5px;
    height: 20px;
    background: #00ff00;
    margin-bottom: -4px;
    display: inline-block;
    animation: cursor-blink 1.5s steps(2) infinite;
}

.ot-sdk-show-settings {
    cursor: pointer;
}

.footer {
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    padding-bottom: 30px;
    background: black;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -27px 35px 17px #000000;
    /* position: absolute; */
    bottom: 0;
    left: 0;
}

.credit {
    font-size: 12px;
    font-family: "AkuKamu";
    margin-top: 30px;
}

@media screen and (min-width: 1024px) {
    #endScreen .footer {
        position: absolute;
    }
}

@media screen and (max-width: 1024px) {
    .footer ul {
        flex-direction: column;
    }

    .footer ul li {
        margin-bottom: 10px;
    }
}

.footer ul {
    width: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-around;
    font-family: "AkuKamu";
}

.footer a {
    color: white;
    text-decoration: none;
}